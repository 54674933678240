import { BrowserRouter, Route, Routes } from "react-router-dom"
import Globe from "./routes/globe"
import Place from "./routes/place"

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={Globe} path="/" />
				<Route element={Place} path="/:city" />
			</Routes>
		</BrowserRouter>
	)
}

export default App
