import { Box, Environment, OrbitControls } from "@react-three/drei"
// @ts-ignore
import StarsTexture from "../assets/stars.hdr"
import Earth from "../components/earth"
import Interactive from "../components/interactive"
import WithContextSwitch, {
	ContextSwitchProps,
} from "../components/withContextSwitch"

const Globe = ({ isVR, navigate }: ContextSwitchProps) => {
	return (
		<>
			<fog args={["black", 30, 40]} attach="fog" />

			<ambientLight intensity={0.25} />

			<Interactive isVR={isVR} onClick={() => navigate("/valencia")}>
				<Box>
					<meshStandardMaterial color="white" />
				</Box>
			</Interactive>
			{/* <Plane args={[100, 100, 1, 1]} rotation={[-Math.PI / 2, 0, 0]}>
			<meshStandardMaterial
				attach="material"
				color="black"
				opacity={0.7}
				transparent
			/>
		</Plane> */}

			{/* <mesh rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
			<planeGeometry args={[10, 10]} />
			<MeshReflectorMaterial
				blur={[400, 100]}
				color="#505050"
				depthScale={1}
				metalness={0.6}
				minDepthThreshold={0.85}
				mirror={0.5}
				mixBlur={1}
				mixStrength={3.5}
				resolution={1024}
				roughness={1}
			/>
		</mesh> */}

			<Earth />

			<OrbitControls />

			<Environment background={true} files={StarsTexture} />

			{/* <Stars factor={100} fade={false} saturation={1}  /> */}
		</>
	)
}

export default WithContextSwitch(Globe)
