import { Interactive as InteractiveVR } from "@react-three/xr"
import { FunctionComponent, ReactElement } from "react"

type Props = {
	onClick?: () => void
	children: ReactElement<any, any>
	isVR: boolean
}

const Interactive: FunctionComponent<Props> = ({
	children,
	isVR,
	onClick,
}: Props) => {
	return isVR ? (
		<InteractiveVR onSelect={onClick}>{children}</InteractiveVR>
	) : (
		<group onClick={onClick}>{children}</group>
	)
}

export default Interactive
