import { Box } from "@react-three/drei"
import Interactive from "../components/interactive"
import withContextSwitch, {
	ContextSwitchProps,
} from "../components/withContextSwitch"

const Place = ({ isVR, navigate, params: { city } }: ContextSwitchProps) => {
	console.log(city)

	return (
		<>
			<ambientLight intensity={0.25} />

			<Interactive isVR={isVR} onClick={() => navigate("/valencia")}>
				<Box>
					<meshStandardMaterial color="white" />
				</Box>
			</Interactive>
		</>
	)
}

export default withContextSwitch(Place)
