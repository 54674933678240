import { Canvas } from "@react-three/fiber"
import { DefaultXRControllers, Hands, VRCanvas } from "@react-three/xr"
import { ComponentType, Suspense, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

export type ContextSwitchProps = {
	isVR: boolean
	navigate: (to: string) => void
	params: any
}

const withContextSwitch = (Component: ComponentType<ContextSwitchProps>) => {
	const ContextSwitch = ({
		Component,
		...props
	}: {
		Component: ComponentType<ContextSwitchProps>
	}) => {
		const [hasVR, setHasVR] = useState<undefined | boolean>(undefined)

		const navigate = useNavigate()
		const params = useParams()

		useEffect(() => {
			const getVRState = async () => {
				try {
					// @ts-ignore
					const hasVR = await navigator.xr.isSessionSupported("immersive-vr")
					setHasVR(hasVR)
				} catch (e) {
					setHasVR(false)
				}
			}

			getVRState()
		}, [])

		return (
			<div className="bg-slate-500 w-screen h-screen">
				{hasVR !== undefined && hasVR ? (
					<VRCanvas gl={{ alpha: false }}>
						<Suspense fallback={null}>
							<Hands />
							<DefaultXRControllers />

							<Component
								isVR={true}
								navigate={navigate}
								params={params}
								{...props}
							/>
						</Suspense>
					</VRCanvas>
				) : (
					<Canvas gl={{ alpha: false }} shadows>
						<Suspense fallback={null}>
							<Component
								isVR={false}
								navigate={navigate}
								params={params}
								{...props}
							/>
						</Suspense>
					</Canvas>
				)}
			</div>
		)
	}

	return <ContextSwitch Component={Component} />
}

export default withContextSwitch
