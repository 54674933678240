import { Sphere, useTexture } from "@react-three/drei"
import EarthTexture from "../assets/earth_small.jpg"

const Earth = () => {
	const earthTexture = useTexture(EarthTexture)
	// const earthTextureEm = useTexture(EarthTexture)

	return (
		<Sphere
			args={[1, 32, 64, undefined, undefined, undefined, undefined]}
			position={[0, -10, -20]}
			rotation={[0, -(2 * Math.PI) / 4 - 0.1, -0.3]}
			scale={20}
		>
			<meshBasicMaterial
				attach="material"
				// emissive={new Color(1, 1, 1)}
				// emissiveIntensity={100}
				// emissiveMap={earthTextureEm}
				map={earthTexture}
			/>
		</Sphere>
	)
}

export default Earth
